import request from '@/utils/request'


// 查询提现比例配置列表
export function listWithdrawRatio(query) {
  return request({
    url: '/platform/withdraw-ratio/list',
    method: 'get',
    params: query
  })
}

// 查询提现比例配置分页
export function pageWithdrawRatio(query) {
  return request({
    url: '/platform/withdraw-ratio/page',
    method: 'get',
    params: query
  })
}

// 查询提现比例配置详细
export function getWithdrawRatio(data) {
  return request({
    url: '/platform/withdraw-ratio/detail',
    method: 'get',
    params: data
  })
}

// 新增提现比例配置
export function addWithdrawRatio(data) {
  return request({
    url: '/platform/withdraw-ratio/add',
    method: 'post',
    data: data
  })
}

// 修改提现比例配置
export function updateWithdrawRatio(data) {
  return request({
    url: '/platform/withdraw-ratio/edit',
    method: 'post',
    data: data
  })
}

// 删除提现比例配置
export function delWithdrawRatio(data) {
  return request({
    url: '/platform/withdraw-ratio/delete',
    method: 'post',
    data: data
  })
}
